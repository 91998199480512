import React from 'react';
import { Button, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import useMediaQuery from '../hooks/useMediaQuery';

const Header = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  if (isMobile) {
    return (
      <header className="header mobile">
        <Button 
          type="text" 
          className="stay-up-to-date-btn"
        >
          Stay up to date
        </Button>
        <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="Logo" className="logo" />
        <Button 
          type="primary" 
          className="register-btn"
        >
          Register
          <ArrowRightOutlined style={{ marginLeft: '4px' }} />
        </Button>
      </header>
    );
  }

  return (
    <header className="header">
      <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="Logo" style={{ height: '20px' }} />
      <Space size="large">
        <Button 
          type="text" 
          style={{ 
            color: 'white',
            padding: '12px 20px',
            height: 'auto'
          }}
        >
          Stay up to date
        </Button>
        <Button 
          type="primary" 
          style={{ 
            display: 'flex', 
            alignItems: 'center', 
            background: 'white', 
            color: '#333',
            padding: '12px 20px', 
            height: 'auto' 
          }}
        >
          Register
          <ArrowRightOutlined style={{ marginLeft: '4px' }} />
        </Button>
      </Space>
    </header>
  );
};

export default Header;