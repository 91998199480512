import React from 'react';
import { ConfigProvider } from 'antd';
import Header from './components/Header';
import HowItWorks from './components/HowItWorks';
import FeatureCards from './components/FeatureCards';
import useMediaQuery from './hooks/useMediaQuery';

// Import Ant Design CSS
import 'antd/dist/reset.css';

function App() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const containerStyle = {
    backgroundColor: '#F5F5F7',
    minHeight: '100vh',
    padding: isMobile ? '31px 6px' : '31px'
  };

  return (
    <ConfigProvider>
      <div style={containerStyle}>
        <div className="landing-page-container">
          <div className="landing-page-background" />
          <div className="content-wrapper">
            <Header />
          </div>
        </div>
        <div className="text-content">
          <h1 className="main-title">Turn Moments of Confusion into Moments of Clarity with AR.</h1>
          <p className="subtitle">Real-time reminders of names and relationships for Alzheimer's patients on iOS, iPadOS, and VisionOS.</p>
        </div>
        <HowItWorks />
        <FeatureCards />
      </div>
    </ConfigProvider>
  );
}

export default App;