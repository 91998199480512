import React from 'react';
import { Typography } from 'antd';
import useMediaQuery from '../hooks/useMediaQuery';

const { Title, Paragraph, Text } = Typography;

const HowItWorks = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const containerStyle = {
    maxWidth: isMobile ? '100%' : '1500px',
    margin: isMobile ? '40px auto 0' : '70px auto 0',
    backgroundImage: isMobile 
      ? 'url(/assets/images/app-screenshot-mobile.png)'
      : 'url(/assets/images/app-screenshot.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: isMobile ? '690px' : '800px', // Adjust based on your image's aspect ratio
    position: 'relative',
  };

  const contentStyle = {
    position: isMobile ? 'static' : 'absolute',
    top: isMobile ? 'auto' : '50px',
    left: isMobile ? 'auto' : '16%',
    width: isMobile ? '90%' : '35%', // Reduced width for mobile
    maxWidth: isMobile ? '400px' : 'none', // Added max-width for mobile
    maxHeight: isMobile ? 'none' : '70%',
    overflowY: isMobile ? 'visible' : 'auto',
    padding: isMobile ? '20px 6px' : '20px',
    margin: isMobile ? '0 auto' : '0', // Center content on mobile
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <Title level={2} style={{ fontSize: isMobile ? '40px' : '70px', marginBottom: '12px' }}>How It Works</Title>
        {!isMobile && (
          <Paragraph style={{ fontSize: '14px', marginBottom: '10px' }}>
            Easily help your loved one recognize familiar faces by creating personalized AR tags. Here's how:
          </Paragraph>
        )}
        <ol style={{ paddingLeft: isMobile ? '20px' : '16px', margin: 0, paddingTop: isMobile ? '10px' : '20px' }}>
          <li>
            <Text strong>Pair with Vision Pro</Text>
            <Paragraph style={{ margin: '2px 0 6px' }}>Connect the app to your loved one's Vision Pro.</Paragraph>
          </li>
          <li>
            <Text strong>Create Your Tag</Text>
            <Paragraph style={{ margin: '2px 0 6px' }}>Enter your name, relationship, and an optional memory cue.</Paragraph>
          </li>
          <li>
            <Text strong>Upload Photos</Text>
            <Paragraph style={{ margin: '2px 0 6px' }}>Add photos for better recognition.</Paragraph>
          </li>
          <li>
            <Text strong>Privacy & Consent</Text>
            <Paragraph style={{ margin: '2px 0 6px' }}>Review and approve the privacy agreement.</Paragraph>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default HowItWorks;