import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import useMediaQuery from '../hooks/useMediaQuery';

const { Title, Paragraph } = Typography;

const FeatureCards = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const containerStyle = {
    maxWidth: isMobile ? '100%' : '1110px',
    margin: '0 auto',
    padding: isMobile ? '50px 6px 0' : '0 31px'
  };

  const cardStyle = {
    borderRadius: '35px',
    height: isMobile ? 'auto' : '100%',
    marginBottom: isMobile ? '10px' : '0' // Reduced margin between cards for mobile
  };

  const cardBodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: isMobile ? '30px' : '40px'
  };

  return (
    <div style={containerStyle}>
      <Row gutter={isMobile ? [0, 10] : 24} style={{ height: isMobile ? 'auto' : '600px' }}>
        <Col span={isMobile ? 24 : 12}>
          <Card 
            style={{ ...cardStyle, backgroundColor: '#007AFF' }}
            bodyStyle={cardBodyStyle}
          >
            <div>
              <Title level={2} style={{ color: 'white', fontSize: isMobile ? '36px' : '48px', marginBottom: '20px' }}>
                Simple Setup & Voice Assistance
              </Title>
              <Paragraph style={{ color: 'white', fontSize: '16px' }}>
                Easily upload photos to build a face database. Ask, "Who is this?" and get real-time verbal feedback, simplifying interactions.
              </Paragraph>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: isMobile ? '20px' : '0' }}>
              <img 
                src="/assets/images/voice-icon.png" 
                alt="Voice Assistance" 
                style={{ width: '60px' }}
              />
            </div>
          </Card>
        </Col>
        <Col span={isMobile ? 24 : 12}>
          <Card 
            style={{ ...cardStyle, backgroundColor: 'white' }}
            bodyStyle={cardBodyStyle}
          >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, marginBottom: isMobile ? '20px' : '0' }}>
              <img 
                src="/assets/images/lock-icon.png" 
                alt="Privacy" 
                style={{ width: '40px' }}
              />
            </div>
            <div>
              <Title level={2} style={{ fontSize: isMobile ? '36px' : '48px', marginBottom: '20px' }}>
                AR Integration & Privacy
              </Title>
              <Paragraph style={{ fontSize: '16px' }}>
                Optimized for iPhone, iPad, and Vision Pro, providing a hands-free experience. All data is securely stored, ensuring privacy and peace of mind.
              </Paragraph>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FeatureCards;